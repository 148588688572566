// ----------------------------------------------------------------------

export default function Badge() {
	return {
		MuiBadge: {
			styleOverrides: {
				dot: {
					width: 10,
					height: 10,
					borderRadius: '50%'
				}
			}
		}
	};
}
