// ----------------------------------------------------------------------

export const varBounceOut = {
	animate: {
		scale: [0.9, 1.1, 0.3],
		opacity: [1, 1, 0]
	}
};

export const varBounceOutUp = {
	animate: {
		y: [-12, 24, -720],
		scaleY: [0.985, 0.9, 3],
		opacity: [1, 1, 0]
	}
};

export const varBounceOutDown = {
	animate: {
		y: [12, -24, 720],
		scaleY: [0.985, 0.9, 3],
		opacity: [1, 1, 0]
	}
};

export const varBounceOutLeft = {
	animate: {
		x: [0, 24, -720],
		scaleX: [1, 0.9, 2],
		opacity: [1, 1, 0]
	}
};

export const varBounceOutRight = {
	animate: {
		x: [0, -24, 720],
		scaleX: [1, 0.9, 2],
		opacity: [1, 1, 0]
	}
};
