import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
import {Icon} from '@iconify/react';
// routes
import {PATH_AUTH, PATH_DASHBOARD, PATH_DOCS, PATH_PAGE} from 'shared/routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
	width: 22,
	height: 22
};

const menuConfig = [
	{
		title: 'Home',
		icon: <Icon icon={homeFill} {...ICON_SIZE} />,
		path: '/'
	},
	{
		title: 'Components',
		icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
		path: PATH_PAGE.components
	},
	{
		title: 'Pages',
		path: '/pages',
		icon: <Icon icon={fileFill} {...ICON_SIZE} />,
		children: [
			{
				subheader: 'Other',
				items: [
					{title: 'FAQs', path: PATH_PAGE.faqs},
					{title: 'Pricing', path: PATH_PAGE.pricing},
					{title: 'Payment', path: PATH_PAGE.payment},
					{title: 'Maintenance', path: PATH_PAGE.maintenance},
					{title: 'Coming Soon', path: PATH_PAGE.comingSoon}
				]
			},
			{
				subheader: 'Authentication',
				items: [
					{title: 'Login', path: PATH_AUTH.loginUnprotected},
					{title: 'Register', path: PATH_AUTH.registerUnprotected},
					{title: 'Reset password', path: PATH_AUTH.resetPassword},
					{title: 'Verify code', path: PATH_AUTH.verify}
				]
			},
			{
				subheader: 'Error',
				items: [
					{title: 'Page 404', path: PATH_PAGE.page404},
					{title: 'Page 500', path: PATH_PAGE.page500}
				]
			},
			{
				subheader: 'Dashboard',
				items: [{title: 'Dashboard', path: PATH_DASHBOARD.root}]
			}
		]
	},
	{
		title: 'Documentation',
		icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
		path: PATH_DOCS
	}
];

export default menuConfig;
