// ----------------------------------------------------------------------

export default function Radio(theme) {
	return {
		MuiRadio: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1)
				}
			}
		}
	};
}
