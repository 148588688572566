import {useSnackbar} from 'notistack5';
import UserListManagement from 'pages/dashboard/admin/UserList';
import DestinationList from 'pages/dashboard/destination/DestinationList';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
// pages
import Login from '../../pages/authentication/Login';
import GeneralApp from '../../pages/dashboard/GeneralApp';

// hooks
import useAuth from '../hooks/useAuth';

AuthGuard.propTypes = {
	children: PropTypes.node
};

export default function AuthGuard({children}) {
	const {isAuthenticated, user, logout} = useAuth();
	const {pathname} = useLocation();
	const [requestedLocation, setRequestedLocation] = useState(null);
	const {enqueueSnackbar} = useSnackbar();

	if (!isAuthenticated) {
		console.log('No permission');
		return <Login />;
	}
	if (user.role.description === 'Khách du lịch') {
		logout();
		return <Login />;
	}
	// if (user.role.description === 'Quản lý' || user.role.description === 'Đối tác') {
	// 	return <Navigate to="/dashboard/destinationList" replace />;
	// }
	// if (user.role.description === 'Quản trị viên') {
	// 	return <UserListManagement />;
	// }
	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
