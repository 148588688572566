// ----------------------------------------------------------------------

export default function IconButton() {
	return {
		MuiIconButton: {
			styleOverrides: {
				root: {}
			}
		}
	};
}
