// ----------------------------------------------------------------------

export default function Timeline(theme) {
	return {
		MuiTimelineDot: {
			styleOverrides: {
				root: {
					boxShadow: 'none'
				}
			}
		},

		MuiTimelineConnector: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.divider
				}
			}
		}
	};
}
